<!--
 * @Description: 新增、修改策略
 * @Author: 琢磨先生
 * @Date: 2022-05-19 11:30:12
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-11 12:31:19
-->

<template>
  <el-drawer
    v-model="drawer"
    :title="title"
    :close-on-click-modal="false"
    size="1000px"
    class="custom"
    @closed="onClosed"
  >
    <div v-loading="loading" class="flex-grow flex-column" style="height: 1px;">
      <el-form :model="form" ref="form" :rules="rules" label-width="140px">
        <el-form-item label="应用" prop="app_code">
          <el-select v-model="form.appCode" placeholder @change="appChange" :disabled="form.id > 0">
            <el-option v-for="item in apps" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="父级" v-if="powers.length > 0">
          <el-cascader
            v-model="form.fatherIds"
            :options="powers"
            :props="{
          label: 'label',
          value: 'id',
          checkStrictly: true,
          }"
            clearable
            :disabled="form.id > 0"
          />
        </el-form-item>

        <el-form-item label="名称" prop="name">
          <el-col :span="10">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <el-tabs v-model="activeName">
        <el-tab-pane
          :name="item.key"
          :label="item.name"
          v-for="item in current_app.platforms"
          :key="item.key"
        >
          <el-scrollbar>
            <el-tree
              :ref="(el)=>item.refTree =el"
              :data="item.functions"
              @check-change="powerTreeChange(item)"
              show-checkbox
              default-expand-all
              node-key="id"
              check-strictly
              :props="{label:'name'}"
            >
              <template #default="{ node, data }">
                <span class="custom-tree-node">
                  <span>{{ node.label }}</span>
                  <span v-if="data.url" style="color:#999;">({{data.url}})</span>
                </span>
              </template>
            </el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <!-- <div class="flex-grow" style="height: 1px;">

      </div>-->
    </div>
    <template #footer>
      <div>
        <el-button type="primary" @click="onSubmit" :loading="saving" :disabled="saving">确定</el-button>
        <!-- <el-button @click="cancelClick" :disabled="saving">取消</el-button> -->
      </div>
    </template>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      loading: false,
      saving: false,
      activeName: "",
      title: "新增策略",
      //应用端
      platforms: [],
      //选择父级下拉
      powers: [],
      form: {},
      rules: {
        appCode: [
          {
            required: true,
            message: "请选择应用",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
      },
      //当前选中的应用
      current_app: {},
    };
  },
  props: ["item", "apps", "copy"],
  emits: ["close", "success"],
  created() {},
  watch: {
    item: {
      handler() {
        this.form = {};
        if (this.item) {
          this.platforms = [];
          this.powers = [];
          this.functions = [];
          this.drawer = true;
          this.dtl = null;
          this.form = {
            functionIds: [],
          };
          if (this.item.id) {
            this.form = Object.assign({}, this.item);
            this.loadDtl();
            this.title = this.copy ? "新增" : "修改";
          } else {
            this.title = "新增";
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     * app选择更改
     */
    appChange(key) {
      this.code = key;
      this.current_app = this.apps.find((o) => o.code == key);
      if (this.current_app.platforms.length > 0) {
        this.activeName = this.current_app.platforms[0].key;
        this.current_app.platforms.forEach((item) => {
          item.selection_list = [];
          this.loadFunction(item);
        });
      }
      this.loadPowers();
    },

    /**
     * 获取详情
     */
    loadDtl() {
      this.$http.get("admin/v1/power/dtl?id=" + this.form.id).then((res) => {
        if (res.code == 0) {
          this.dtl = res.data;
          this.form.fatherIds = this.dtl.fatherIds;
          this.form.functionIds = this.dtl.functionIds;
          this.appChange(this.dtl.appCode);
          if (this.copy) {
            delete this.form.id;
            this.loadPowers();
          }
        }
      });
    },

    /**
     *
     * @param {*} nodes
     */
    powerTreeChange(item) {
      item.selection_list = [];
      var rows = item.refTree.getCheckedNodes();
      rows.forEach((x) => {
        item.selection_list.push({
          id: x.id,
          name: x.name,
        });
      });
    },

    /**
     * 获取菜单、API数据
     */
    loadFunction(item) {
      item.loading = true;
      this.$http
        .get("admin/v1/function/tree?appkey=" + item.key)
        .then((res) => {
          if (res.code == 0) {
            item.functions = res.data;
            if (this.dtl) {
              //加载了详情
              this.$nextTick(() => {
                // item.functions.forEach((x) => {
                //   this.defaultCheck(x, item);
                // });
                item.refTree.setCheckedKeys(this.dtl.functionIds);
              });
            }
          }
          item.loading = false;
        });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.fatherIds && this.form.fatherIds.length > 0) {
            this.form.fatherId =
              this.form.fatherIds[this.form.fatherIds.length - 1];
          }
          var functionIds = [];
          this.current_app.platforms.forEach((app) => {
            app.selection_list.forEach((item) => {
              functionIds.push(item.id);
            });
          });
          var model = {
            id: this.form.id,
            appCode: this.form.appCode,
            functionIds: functionIds,
            name: this.form.name,
            fatherId: this.form.fatherId,
          };
          // console.log(model);

          this.saving = true;
          this.$http
            .post("/admin/v1/power/edit", model)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.$emit("success");
              }
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },

    onClosed() {
      this.$emit("close");
    },

    // /**
    //  * 选择的功能数据
    //  */
    // tableSelectionChange(values, app) {
    //   console.log(app);
    //   app.selection_list = values;
    // },
    // /**
    //  * 选择的功能数据
    //  */
    // apiSelectionChange(values) {
    //   this.selected_apis = values;
    // },
    /**
     * 加载数据
     */
    loadPowers() {
      this.$http
        .get(`admin/v1/power/get_tree?code=${this.code}`)
        .then((res) => {
          if (res.code == 0) {
            this.powers = res.data;
          }
        });
    },

    /**
     * 修改时默认选中
     */
    defaultCheck(item, app) {
      if (this.dtl.functionIds.indexOf(item.id) > -1) {
        //存在
        app.refTree.setChecked(item, true);
      }
      if (item.children.length > 0) {
        item.children.forEach((child) => {
          this.defaultCheck(child, app);
        });
      }
    },
  },
};
</script>

<style scoped>
.custom .el-drawer__body {
  display: flex !important;
  flex-direction: column !important;
}
.custom .el-tabs {
  height: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.custom .el-tabs__content {
  flex-grow: 1;
  height: 1px;
}

.custom .el-tab-pane {
  height: 100%;
}

.custom .el-table {
  height: 100% !important;
}
</style>
